@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Bodina Moda';
  src: url('../public/assets/fonts/bodina-moda.ttf');
}

h1 {
  @apply font-black font-bodina text-3xl sm:text-4xl md:text-5xl tracking-tight text-center uppercase;
}

h2 {
  @apply font-black font-bodina text-3xl tracking-wide;
}

p {
  @apply font-bodina text-xl text-center;
}

.prose p {
  @apply font-bodina text-xl text-left;
}
